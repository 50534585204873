import React, { useState } from "react";
import styled from 'styled-components';
import { breakpoints, Section, SectionBody, Column, H2 } from '../layout';

const SectionWrapper = styled.div`
    
`

const FormColumn = styled(Column)`
    display: flex;
    justify-content:center;
    padding: 0;
    margin: 1.5rem 0 0 0;

    ${breakpoints.large} {
        margin: 0;
    }
`

const Form = styled.form`
    width: 100%;

    ${breakpoints.large} {
        width: 80%;
    }
`

const InputWrapper = styled.div`
    margin: 0 0 1.5rem;
`

const Label = styled.label`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    color: #2A8236;
    display: block;
`

const Input = styled.input`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    width: 100%;
    display: block;
    padding: 0.5rem;
    border: 1px solid #5F635F;
    border-radius: 4px;
`

const Textarea = styled.textarea`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    width: 100%;
    display: block;
    padding: 0.5rem;
    border: 1px solid #5F635F;
    border-radius: 4px;
`

const Button = styled.button`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    background-color: #2A8236;
    color: white;
    padding: 0.5rem 4rem;
    border: 0;
    border-radius: 4px;
    width: 100%;
`

const SentMessage = styled.p`
    text-align: center;
    padding-top: 1rem;
`;

const FORM_ENDPOINT = 'https://getform.io/f/147b985c-d0a3-4d9e-9eea-a81418cf2e6c'

const STATUS = {
    sending: 'SENDING',
    sent: 'SENT'
}

const Contact = () => {
    const [status, setStatus] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append(
            'email',
            document.querySelector('input[name="email"').value
        )
        formData.append(
            'phone',
            document.querySelector('input[name="phone"').value
        )
        formData.append(
            'message',
            document.querySelector('textarea[name="message"').value
        )

        if (status !== STATUS.sending) {
            setStatus(STATUS.sending)

            fetch(FORM_ENDPOINT, {
                method: "POST",
                body: formData
            }).then((response) => {
                setStatus(STATUS.sent)
            })
        }
    }

    return (
        <SectionWrapper>
            <Section id="contact">
                <H2>Contact</H2>
                <SectionBody>
                    <Column>
                        <p>Please get in touch using the form. You will receive a response as soon as possible.</p>
                    </Column>
                    <FormColumn>
                        <Form onSubmit={handleSubmit} action={FORM_ENDPOINT} method="POST" target="_blank">
                            <InputWrapper>
                                <Label>Email</Label>
                                <Input name="email" type="email" required />
                            </InputWrapper>
                            <InputWrapper>
                                <Label>Phone</Label>
                                <Input name="phone" type="text" />
                            </InputWrapper>
                            <InputWrapper>
                                <Label>Message</Label>
                                <Textarea name="message" required />
                            </InputWrapper>
                            <Button type="submit">{status === STATUS.sending ? 'Sending...' : 'Send'}</Button>
                            <SentMessage>{status === STATUS.sent ? 'Message Sent!' : ''}</SentMessage>
                        </Form>
                    </FormColumn>
                </SectionBody>
            </Section>
        </SectionWrapper>
    )
}

export default Contact;
