import React from "react";
import styled from "styled-components";
import { breakpoints, Section, SectionBody, Column, H2 } from '../layout';
import photo from '../../images/photo.jpg'
import environmentAward from '../../images/environment-recommended.png'
import planningAward from '../../images/planning-recommended.png'

const SectionWrapper = styled.div`
`

const ImageColumn = styled(Column)`
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    ${breakpoints.large} {
        margin: 0;
    }
`;

const ImageWrapper = styled.div`
    margin: auto;

    ${breakpoints.medium} {
        width: 80%;
    }
`

const Image = styled.img`
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: auto;
`

const AwardsSectionBody = styled(SectionBody)`
    justify-content: center;
    align-items: center;
    padding: 2rem 0 0;

    ${breakpoints.medium} {
        flex-direction: row;

        & > div:first-child {
            padding: 0 1.5rem 0 0;
        }
        & > div:last-child {
            padding: 0 0 0 1.5rem;
        }
    }

    ${breakpoints.large} {
        padding: 3rem 0 0;
        flex-direction: row;

        & > div:first-child {
            padding: 0 3rem 0 0;
        }
        & > div:last-child {
            padding: 0 0 0 3rem;
        }
    }
`

const AwardWrapper = styled.div`
    width: 13rem;
    
    & > img {
        padding: 0 0 2.5rem;
    }

    ${breakpoints.small} {
        width: 13rem;
    }

    ${breakpoints.medium} {
        width: 13rem;
    }

    ${breakpoints.large} {
        width: 18rem;
    }
`
const About = () => {
    return (
        <SectionWrapper>
            <Section id="about">
                <H2>About Polis Legal</H2>
                <SectionBody>
                    <Column>
                        <p>Ellen Tarasenko was admitted to legal practice in 2009 and established Polis Legal in 2022. She spent 13 years of her career at large commercial law firms, where she gained extensive experience working on a range of developments, including residential/mixed use (including affordable housing), greenfields/growth areas, commercial and renewable energy projects.</p>
                        <p>Ellen has specialist expertise in all areas of planning, environment and heritage law, including statutory approvals, precinct and master planning, liaising with authorities and stakeholders, drafting approvals conditions and planning agreements, and advising on implementation and compliance. She also regularly acts for proponents in preparation for and representation at planning appeals, including proceedings in the Victorian Civil and Administrative Tribunal and at Planning Panels Victoria.</p>
                        <p>Her passion lies in developing strategies for her developer and landholder clients in navigating various complex approvals processes. She understands the commercial drivers of her clients and recognises the purpose of stakeholder interests and engagement. Her critical thinking and strategic advice can assist with meeting objectives and achieving practical solutions.</p>
                    </Column>
                    <ImageColumn>
                        <ImageWrapper>
                            <Image src={photo}/>
                        </ImageWrapper>
                    </ImageColumn>
                </SectionBody>
                <AwardsSectionBody>
                    <AwardWrapper>
                        <Image src={planningAward} />
                    </AwardWrapper>
                    <AwardWrapper>
                        <Image src={environmentAward} />
                    </AwardWrapper>
                </AwardsSectionBody>
            </Section>
        </SectionWrapper>
    )
}

export default About;
